import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';

export function MobileHeader() {
    const [isOpen, setIsOpen] = useState(false);

    

    return (
        <header className="w-full bg-gray-400 py-5 px-6 sm:hidden">
            <div className="flex items-center justify-between">
                <a href="index.html" className="text-black text-3xl font-semibold pacifico-regular hover:text-gray-300">Gubat One Community</a>
                <button onClick={() => setIsOpen(!isOpen)} className="text-white text-3xl focus:outline-none">
                    {!isOpen ? (
                        <i className="fas fa-bars"></i>
                    ) : (
                        <i className="fas fa-times"></i>
                    )}
                </button>
            </div>

            {/* Dropdown Nav */}
            <nav className={`flex flex-col pt-4 ${isOpen ? 'flex' : 'hidden'}`}>
                <Link to='/' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-tachometer-alt mr-3"></i> */}
                    Home
                </Link>
                <Link to='/about' className="flex items-center text-black py-2 pl-4 nav-item">
                    {/* <i className="fas fa-sticky-note mr-3"></i> */}
                    About
                </Link>
                {/* <Link to='/local-static' className="flex items-center text-white opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    <i className="fas fa-table mr-3"></i>
                    Local IP
                </Link> */}
                <Link to='/news' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-align-left mr-3"></i> */}
                    Community News
                </Link>
                <Link to='/marketplace' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-align-left mr-3"></i> */}
                    Barangay Marketplace
                </Link>
                <Link to='/magsasaka' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-align-left mr-3"></i> */}
                    Magsasaka
                </Link>
                <Link to='/b-games' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-align-left mr-3"></i> */}
                    B-Games
                </Link>
                
                
                
            </nav>
        </header>
    );
}

export default MobileHeader;
