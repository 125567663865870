import React, { useState, useEffect } from 'react'; // Importing useState and useEffect
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function PendingProducts() {
  const [approvedProducts, setApprovedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productsLoading, setProductsLoading] = useState(true);

  // Fetch approved products on component mount
  useEffect(() => {
    const fetchApprovedProducts = async () => {
      try {
        const response = await fetch('http://tatakb.dyndns.org:9001/api/pending-products');
        if (!response.ok) throw new Error('Failed to fetch approved products');
        const data = await response.json();
        setApprovedProducts(data);
      } catch (error) {
        setErrorMessage('Unable to load approved products.');
      } finally {
        setProductsLoading(false);
      }
    };

    fetchApprovedProducts();
  }, []);

  // Handle approve action
  const handleApprove = async (productId) => {
    try {
      const response = await fetch(`http://tatakb.dyndns.org:9001/api/approve-product/${productId}`, {
        method: 'POST', // or PUT depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to approve product');
      
      // Update UI after approving
      setApprovedProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
      alert('Product approved!');
    } catch (error) {
      setErrorMessage('Failed to approve the product.');
    }
  };

  // Handle reject action
  const handleReject = async (productId) => {
    try {
      const response = await fetch(`http://tatakb.dyndns.org:9001/api/reject-product/${productId}`, {
        method: 'POST', // or PUT depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to reject product');
      
      // Update UI after rejecting
      setApprovedProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
      alert('Product rejected!');
    } catch (error) {
      setErrorMessage('Failed to reject the product.');
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center mt-12">Pending Products</h2>

      {productsLoading ? (
        <p className="text-center text-gray-600">Loading pending products...</p>
      ) : approvedProducts.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-6">
          {approvedProducts.map((product) => {
            const parsedImages = product.images ? JSON.parse(product.images) : []; // Safe parsing
            return (
              <div key={product.id} className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800">{product.product}</h3>
                  <p className="text-gray-600 mt-2">{product.description}</p>
                  <p className="text-gray-600 mt-2">Barangay: {product.barangay_name}</p>
                  <p className="text-gray-600 mt-2">Posted by: {product.user_name}</p>
                  <p className="text-gray-600 mt-2">Created on: {new Date(product.created_at).toLocaleString()}</p>
                </div>
                
                {parsedImages.length > 0 ? (
                  <Carousel showThumbs={false} infiniteLoop autoPlay>
                    {parsedImages.map((image, index) => (
                      <div key={index}>
                        <img
                          src={`http://tatakb.dyndns.org:9001${image}`}
                          alt={`Product image ${index + 1}`}
                          className="w-full h-64 object-cover"
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className="p-4 text-center text-gray-500">No images available for this product.</div>
                )}

                {/* Approve and Reject Buttons */}
                <div className="p-4 flex gap-2 justify-end">
                  <button
                    onClick={() => handleApprove(product.id)}
                    className="bg-green-500 text-white px-4 py-2  hover:bg-green-600"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleReject(product.id)}
                    className="bg-red-500 text-white px-4 py-2 hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-center text-gray-600">No pending products available.</p>
      )}
      {errorMessage && <p className="text-center text-red-500">{errorMessage}</p>}
    </div>
  );
}

export default PendingProducts;
