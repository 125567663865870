// src/components/Unauthorized.js

import React from 'react';

const Unauthorized = () => {
  return (
    <div className="p-6">
      <h1 className="text-xl">403 Forbidden</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default Unauthorized;
