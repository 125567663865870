import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/abouttabi.jpg'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Tabi() {
    // Optional: Define or import the `transition` function if necessary
    const transition = () => {
        console.log('Navigating...');
    };

    return (

        <>
            {/* WHO ARE WE SECTION */}
            <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
                <AnimatedPage>
                    <div className="container mx-auto">
                        <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
                            <div
                                className="col-span-4 sm:col-span-4 md:col-span-6"
                                data-aos="zoom-in"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="500"
                            >
                                <img
                                    src={WhoWeAre}
                                    alt="Community"
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                                <h2
                                    className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    What is Tabi?
                                </h2>
                                <div>
                                    <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Tabi was once a sitio of Barangay Buenavista. Perhaps it is the oldest settlement in the municipality since it was named "alamag". Before Gubat had a new name and change from Ariman, the original name of Ariman was Aramag.

                                        The barangay fit its present name from the Tagalog word meaning "at the side". The settlement in Barangay Tabi are located in such a way that all of them are located beside the river. In Tagalog, "ang mga bahay ay nasa tabi ng ilog". So, whenever the houses were referred, they simply say "sa tabi ng ilog". Later on "sa tabi" were frequently used meaning at Tabi.

                                        During the American occupation in the beginning of the 20th century, the people of this barangay who fled to hide from the Americans were encouraged to return to their settlement. So in 1901, Tabi began its organization as a barrio through the efforts of Cabeza Esteban Esperida, Cabeza Simon Encinas, and Cabeza Victor Bagasala. In 1902, Barangay Tabi became an independent barangay, Eduardo Erlano was appointed as the first Teniente del Barrio.

                                        Formal education in Tabi begun in 1902, Through the benevolence of Victor Bagasala, 3.5 hectares of land was donated by him to became the school site. He also donated the lot which now become the barangay site. The first class was organized by Mr. Arsenio Rocha in a self-help school building.
                                    </p>
                                    {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                                </div>
                                <div
                                    className="text-right py-5 px-5"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    <Link to="/about" onClick={transition}>
                                        <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                                            See more
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedPage>
            </section>
        </>


    );
}

export default Tabi;
