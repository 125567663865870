import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../AxiosInstance';
import { AuthContext } from '../../auth/AuthContext';
import { FaEdit } from 'react-icons/fa';

const CreateUser = () => {
    const [name, setName] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [roles, setRoles] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const [editUser, setEditUser] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalName, setModalName] = useState('');
    const [modalEmail, setModalEmail] = useState('');
    const [modalRole, setModalRole] = useState('');
    const [modalPassword, setModalPassword] = useState('');
    const [modalConfirmPassword, setModalConfirmPassword] = useState('');

    const { isAuthenticated, userRole } = useContext(AuthContext);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get('/users');
                setUsers(response.data);
            } catch (err) {
                console.error('Failed to fetch users', err);
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await axiosInstance.get('/roles');
                setRoles(response.data);
            } catch (err) {
                console.error('Failed to fetch roles', err);
            }
        };

        Promise.all([fetchUsers(), fetchRoles()]).finally(() => setLoading(false));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (userRole === null || userRole !== 1) {
            setError('Unauthorized: You do not have permission to create users.');
            return;
        }

        try {
            await axiosInstance.post('/register', {
                name,
                email,
                password,
                role_id: selectedRole,
            });
            setError('');
            setName('');
            setSelectedRole('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');

            const response = await axiosInstance.get('/users');
            setUsers(response.data);
        } catch (err) {
            setError('Failed to create user.');
            console.error(err);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (modalPassword !== modalConfirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            await axiosInstance.put(`/users/${editUser.id}`, {
                name: modalName,
                email: modalEmail,
                password: modalPassword,
                role_id: modalRole,
            });
            setError('');
            setModalVisible(false);
            setEditUser(null);

            const response = await axiosInstance.get('/users');
            setUsers(response.data);
        } catch (err) {
            setError('Failed to update user.');
            console.error(err);
        }
    };

    const openEditModal = (user) => {
        setEditUser(user);
        setModalName(user.name);
        setModalEmail(user.email);
        setModalRole(user.role ? user.role.id : '');
        setModalPassword('');
        setModalConfirmPassword('');
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setEditUser(null);
    };

    if (loading) return <p>Loading...</p>;

    return (
        <>
            <div className='flex flex-wrap overflow-x-hidden'>
                <div className="w-full lg:w-1/3 mt-6 p-6">
                    <p className="text-xl pb-6 flex items-center">
                        <i className="fa fa-plus mr-3" aria-hidden="true"></i> Create User
                    </p>

                    <form className="bg-white rounded shadow-xl p-6" onSubmit={handleSubmit}>
                        <div>
                            {/* Create user form fields */}
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="name">Name</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    placeholder="Name"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="role">Role</label>
                                <select
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="role_id"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                    required
                                >
                                    <option value="">Select Role</option>
                                    {roles.map((role) => (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="email">Email</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Email"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="password">Password</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    placeholder="Password"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    placeholder="Confirm Password"
                                />
                            </div>

                            {error && <p className="text-red-500">{error}</p>}

                            <div className="inline-block mt-2 w-1/2 pl-1 pb-3">
                                <button className="px-4 py-2 mb-2 text-white font-light bg-blue-900 w-full" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="w-full lg:w-2/3 mt-6 mb-2 p-6">
                    <p className="text-xl pb-6 flex items-center">
                        <i className="fas fa-list mr-3"></i> User Accounts
                    </p>

                    <div className="bg-white overflow-auto">
                        <table className="min-w-full bg-white pr-2">
                            <thead className="bg-gray-800 text-white">
                                <tr>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Name</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Email Address</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Role</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700">
                                {users.map((user) => (
                                    <tr key={user.id} className='hover:bg-blue-200'>
                                        <td className="text-left py-3 px-4">{user.name}</td>
                                        <td className="text-left py-3 px-4">{user.email}</td>
                                        <td className="text-left py-3 px-4">
                                            {user.role_id ? (user.role_id === 1 ? "Superadmin" : "Barangay Admin") : "N/A"}
                                        </td>

                                        <td className="text-left py-3 px-4">
                                            <button
                                                className="text-blue-600 hover:underline"
                                                onClick={() => openEditModal(user)}
                                            >
                                                <FaEdit />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Edit User Modal */}
            {modalVisible && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded shadow-lg w-1/3 p-6">
                        <h2 className="text-xl font-semibold mb-4">Edit User</h2>
                        <form onSubmit={handleUpdate}>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="modalName">Name</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="modalName"
                                    type="text"
                                    value={modalName}
                                    onChange={(e) => setModalName(e.target.value)}
                                    required
                                    placeholder="Name"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="modalEmail">Email</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="modalEmail"
                                    type="email"
                                    value={modalEmail}
                                    onChange={(e) => setModalEmail(e.target.value)}
                                    required
                                    placeholder="Email"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="modalRole">Role</label>
                                <select
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="modalRole"
                                    value={modalRole}
                                    onChange={(e) => setModalRole(e.target.value)}
                                    required
                                >
                                    <option value="">Select Role</option>
                                    {roles.map((role) => (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="modalPassword">Password</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="modalPassword"
                                    type="password"
                                    value={modalPassword}
                                    onChange={(e) => setModalPassword(e.target.value)}
                                    placeholder="Password"
                                />
                            </div>
                            <div className="mt-2 pb-3">
                                <label className="block text-sm text-gray-600" htmlFor="modalConfirmPassword">Confirm Password</label>
                                <input
                                    className="w-full pl-6 py-2 text-gray-700 bg-gray-200"
                                    name="modalConfirmPassword"
                                    type="password"
                                    value={modalConfirmPassword}
                                    onChange={(e) => setModalConfirmPassword(e.target.value)}
                                    placeholder="Confirm Password"
                                />
                            </div>

                            {error && <p className="text-red-500">{error}</p>}

                            <div className="flex justify-end gap-2 mt-4">
                                <button
                                    className="px-4 py-2 text-white font-light bg-blue-900"
                                    type="submit"
                                >
                                    Update
                                </button>
                                <button
                                    className="px-4 py-2 text-white font-light bg-red-600"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateUser;
