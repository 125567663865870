import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/Bulacao.jpg'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Bulacao() {
    // Optional: Define or import the `transition` function if necessary
    const transition = () => {
        console.log('Navigating...');
    };

    return (

        <>
            {/* WHO ARE WE SECTION */}
            <section className="font-poppins pt-10 px-5 md:px-5 lg:px-10 overflow-y-auto">
                <div className='flex justify-end mb-8 text-lg  font-semibold'>
                    <span className='py-4 px-8 border border-2 border-green-500'>Brgy Emergency Hotline # 811</span>
                </div>
                <AnimatedPage>
                    <div className="container mx-auto">
                        <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
                            <div
                                className="col-span-4 sm:col-span-4 md:col-span-6"
                                data-aos="zoom-in"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="500"
                            >
                                <img
                                    src={WhoWeAre}
                                    alt="Community"
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                                <h2
                                    className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    What is Bulacao?
                                </h2>
                                <div>
                                    <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Barangay Bulacao, one of the oldest barangays in Gubat, derives its name from the word bulalacao (or bulalakaw), meaning "meteor." According to local folklore passed down by elders, the area was first settled by a group of nomadic hunters led by a woman named Maria Boncala. These early settlers were drawn to the region for its abundant wildlife and fertile land.

                                        One fateful night, as the hunters waited near their traps, a dazzling bulalacao—a meteor—streaked across the sky and landed nearby. Believing the meteor might contain an anting-anting (amulet) that would grant them special powers, the hunters began an exhaustive search for it.

                                        During their search, a stranger passed by and inquired about the name of the place. Unfamiliar with the stranger’s language, the hunters replied "bulalacao," referring to the meteor. Weeks later, the stranger returned with families from Albay, seeking Maria Boncala's permission to settle in the area. She graciously agreed, paving the way for a thriving community.

                                        Bulacao was officially recognized as a barrio in 1870, with Tomas Escurel serving as its first head. Today, Barangay Bulacao stands as a historic and culturally rich area, deeply rooted in the traditions and stories of its past.                                    </p>
                                    
                                </div>
                                <div
                                    className="text-right py-5"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    <Link to="/barangays" onClick={transition}>
                                        <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                                            See other barangays
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedPage>
            </section>
        </>


    );
}

export default Bulacao;
