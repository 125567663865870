import { useState, useEffect } from 'react';

function PendingNews() {
    const [approvedNews, setApprovedNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(true);
    const [newsError, setNewsError] = useState('');

    const fetchApprovedNews = async () => {
        try {
            const response = await fetch('http://tatakb.dyndns.org:9001/api/pending-news');
            if (!response.ok) {
                throw new Error('Failed to fetch approved news');
            }
            const data = await response.json();
            setApprovedNews(data);
        } catch (error) {
            setNewsError('Error fetching approved news.');
        } finally {
            setNewsLoading(false);
        }
    };

    const handleApprove = async (newsId) => {
        try {
            const response = await fetch(`http://tatakb.dyndns.org:9001/api/approve-news/${newsId}`, {
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error('Failed to approve the news');
            }
            // Update the UI by removing the approved news from the pending list
            setApprovedNews((prevNews) => prevNews.filter((news) => news.id !== newsId));
        } catch (error) {
            setNewsError('Error approving the news.');
        }
    };

    const handleReject = async (newsId) => {
        try {
            const response = await fetch(`http://tatakb.dyndns.org:9001/api/reject-news/${newsId}`, {
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error('Failed to reject the news');
            }
            // Remove the rejected news from the list
            setApprovedNews((prevNews) => prevNews.filter((news) => news.id !== newsId));
        } catch (error) {
            setNewsError('Error rejecting the news.');
        }
    };

    useEffect(() => {
        fetchApprovedNews();
    }, []);

    return (
        <div className="mt-12 overflow-y-auto">
            <h3 className="mb-6 text-2xl font-semibold text-gray-800 pl-4 lg:pl-0 text-center">Pending Community News</h3>

            {newsLoading ? (
                <div>Loading approved news...</div>
            ) : newsError ? (
                <div className="text-red-500">{newsError}</div>
            ) : (
                <div className="space-y-6 mx-auto max-w-7xl sm:px-6 lg:px-8 mt-12 mb-8 ">
                    {approvedNews.map((news) => (
                        <div key={news.id} className="rounded-lg bg-white p-6 shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">
                            <p className="mt-2 text-sm text-gray-500 text-right">Created on: {new Date(news.created_at).toLocaleString()}</p>

                            {news.image && (
                                <div className="flex justify-center">
                                    <img
                                        src={`http://tatakb.dyndns.org:9001${news.image}`}
                                        alt={news.title}
                                        className="mt-4 mb-4 lg:w-[1200px] lg:h-[600px] w-full object-stretch"
                                    />
                                </div>
                            )}

                            <h4 className="mt-4 text-2xl font-semibold text-gray-800">{news.title}</h4>

                            {/* Render content as HTML */}
                            <div className="mt-2 text-gray-600 text-lg leading-relaxed"
                                dangerouslySetInnerHTML={{ __html: news.content }} />

                            {/* Approve and Reject buttons */}
                            <div className="mt-4 flex justify-end gap-2">
                                <button
                                    onClick={() => handleApprove(news.id)}
                                    className="bg-green-500 text-white px-4 py-2 hover:bg-green-600"
                                >
                                    Approve
                                </button>
                                <button
                                    onClick={() => handleReject(news.id)}
                                    className="bg-red-500 text-white px-4 py-2 hover:bg-red-600"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default PendingNews;
