import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/barangayariman-img.jpg'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Ariman() {
    // Optional: Define or import the `transition` function if necessary
    const transition = () => {
        console.log('Navigating...');
    };

    return (
        
        <>
            {/* WHO ARE WE SECTION */}
            <div className='flex'>
                <div className='w-1/7'>
                <div className='flex flex-col pt-20'>
                    <div>About</div>
                    <div>Community News</div>
                    <div>Barangay Marketplace</div>
                </div>
                </div>
                <div className='w-6/7'>
                    <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
                        <AnimatedPage>
                            <div className="container mx-auto">
                                <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
                                    <div
                                        className="col-span-4 sm:col-span-4 md:col-span-6"
                                        data-aos="zoom-in"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        <img
                                            src={WhoWeAre}
                                            alt="Community"
                                            className="w-full h-full"
                                        />
                                    </div>
                                    <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                                        <h2
                                            className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in-out"
                                            data-aos-duration="500"
                                        >
                                            What is Ariman?
                                        </h2>
                                        <div>
                                            <p
                                                className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                                                data-aos="fade-up"
                                                data-aos-easing="ease-in-out"
                                                data-aos-duration="500"
                                            >
                                                Ariman takes its name from a river that originates in Sitio Ariman (Odoc) of Barangay Bentuco in this municipality. The river flows into the Pacific Ocean, with its mouth located in Barangay Ariman.

                                                Historically, Ariman is believed to have been the settlement site of the town's early inhabitants. One account of the name's origin involves a group of Spaniards seeking a route inland. When they asked the villagers which river would lead to the mountains, the locals responded, "Arin man", meaning "whichever" route one chooses will eventually lead to the mountains.

                                                Originally a sitio of Barangay Buenavista, Ariman was officially established as a barangay in 1906, named in honor of Nuestra Señora del Carmen and San Francisco de Asis. Its first Teniente del Barrio was Custodio Estrellado.

                                                A breeding station was once constructed in the barangay, though today only its foundation remains visible from the road.</p>
                                            {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                                        </div>
                                        <div
                                            className="text-right py-5"
                                            data-aos="fade-up"
                                            data-aos-easing="ease-in-out"
                                            data-aos-duration="500"
                                        >
                                            <Link to="/barangays" onClick={transition}>
                                                <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                                                    See other barangays
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AnimatedPage>
                    </section>

                </div>
            </div>
        </>


    );
}

export default Ariman;
