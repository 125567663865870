import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/noimage.png'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Villareal() {
  // Optional: Define or import the `transition` function if necessary
  const transition = () => {
    console.log('Navigating...');
  };

  return (

    <>
      {/* WHO ARE WE SECTION */}
      <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
        <AnimatedPage>
          <div className="container mx-auto">
            <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
              <div
                className="col-span-4 sm:col-span-4 md:col-span-6"
                data-aos="zoom-in"
                data-aos-easing="ease-in-out"
                data-aos-duration="500"
              >
                <img
                  src={WhoWeAre}
                  alt="Community"
                  className="w-full h-full"
                />
              </div>
              <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                <h2
                  className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  What is Villareal?
                </h2>
                <div>
                  <p
                    className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="500"
                  >
                    Barangay Villareal, before it became a barangay, was known as Maragadao and it was a sitio of Malobago (Rizal). It was recognized as a barangay in 1918 during the term of Presidente Municipal Clemente Villarroya.

                    The name Villareal was first used in lieu of Maragadao after the first set of barrio officials was inducted to office. The officials adopted the name Villareal in Honor of Presidente Municipal Clemente Villarroya as their gesture of gratitude for making sitio a separate barangay.

                    The first set of barrio officials were Anacleto Encela as Teniente del Barrio; and Rufo Espenida, Vidal Encinares, Francisco Escandor, Leoncio Estrellado and Eugenio Encinares as barangay councilors.
                  </p>
                  {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                </div>
                <div
                  className="text-right py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  <Link to="/barangays" onClick={transition}>
                    <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                      See other barangays
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </AnimatedPage>
      </section>
    </>


  );
}

export default Villareal;
