import React, { Component } from 'react'
import axiosInstance from '../../AxiosInstance';

export class ExpiredPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: []
        };
    }

    componentDidMount() {
        // Fetch the billing data from the API using axiosInstance
        axiosInstance.get('/billings')
            .then(response => {
                // Filter for expired payments
                const expiredPayments = response.data.filter(payment => payment.status === "Expired");
                this.setState({ payments: expiredPayments });
            })
            .catch(error => console.error('Error fetching data:', error));
    }
  render() {
    const { payments } = this.state;

    const url = 'http://157.20.8.25:8000/storage';

    return (
        <div>
            <div className="w-full mt-6 px-6">
                <p className="text-xl pb-3 flex items-center justify-between">
                    <span><i className="fas fa-list mr-3"></i>Expired Payments</span>
                    <input
                        type="text"
                        className='w-1/3 py-3 px-4 rounded-full border border-green-900'
                        placeholder='Search Voucher Code'
                    />
                </p>
                <div className="bg-white overflow-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-800 text-white">
                            <tr>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Account Name</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Plan Name</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Voucher Code</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Amount</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Date Paid</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Proof of Payment</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Reference Number</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Status</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {payments.map(payment => (
                                payment.hotspots.map(hotspot => (
                                    <tr key={hotspot.id}>
                                        <td className="text-left py-3 px-4">{payment.client.account_name}</td>
                                        <td className="text-left py-3 px-4">{payment.plan.plan_name}</td>
                                        <td className="text-left py-3 px-4">{hotspot.voucher}</td>
                                        <td className="text-left py-3 px-4">{payment.plan.amount}</td>
                                        <td className="text-left py-3 px-4">{payment.date_paid}</td>
                                        <td className="text-left py-3 px-4">
                                            <a href={`${url}/${payment.proof_of_payment}`} className="hover:text-blue-500" target="_blank" rel="noopener noreferrer">
                                                View Proof
                                            </a>
                                        </td>
                                        <td className="text-left py-3 px-4">{payment.reference_number || 'N/A'}</td>
                                        <td className="text-left py-3 px-4">{payment.status}</td>
                                        <td className="text-left py-3 px-4">
                                            <button className="bg-green-800 text-white py-1 px-3 ">Action</button>
                                        </td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }
}

export default ExpiredPayments
