import React from 'react'
import './games.css'

function Games() {
  return (
    <section id="game">
      <div className="container mx-auto px-3">
        <div className=" flex flex-col gap-10 items-center justify-center flex-wrap h-screen z-10 px-3">
          <h2 className="text-[2rem] font-semibold font-poppins text-center border capitalize px-2 py-2 md:text-[4rem] 2xl:text-5xl" data-aos="zoom-in" data-aos-easing="ease-in-out" data-aos-duration="600">
            GUBAT COMMUNITY
          </h2>
          <h2 className="mb-24 text-[4rem] sm:text-[4rem] md:text-[5rem] text-center pacifico-regular 2xl:text-[8rem]" data-aos="zoom-in" data-aos-easing="ease-in-out" data-aos-duration="500">
            United As One
          </h2>
        </div>
      </div>
    </section>
  )
}

export default Games