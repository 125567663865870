import axios from 'axios';

const api = axios.create({
  baseURL: 'http://tatakb.dyndns.org:9001/api',
});

export const register = (name, email, password) => api.post('/register', { name, email, password });
export const login = (email, password) => api.post('/login', { email, password });
export const logout = () => {
  const token = localStorage.getItem('token');
  return api.post('/logout', {}, { headers: { Authorization: `Bearer ${token}` } });
};
export const getUser = () => {
  const token = localStorage.getItem('token');
  return api.get('/user', { headers: { Authorization: `Bearer ${token}` } });
};
