import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/Ogao.jpg'; // Update the path to your image file

function Ogao() {
    // Optional: Define or import the `transition` function if necessary
    const transition = () => {
        console.log('Navigating...');
    };

    return (
        <>
            {/* WHO ARE WE SECTION */}
            <section className="font-poppins pt-10 px-5 md:px-5 lg:px-10 overflow-y-auto">
                <div className='flex justify-end mb-8 text-lg  font-semibold'>
                    <span className='py-4 px-8 border border-2 border-green-500'>Brgy Emergency Hotline # 711</span>
                </div>
                <div className="container mx-auto">
                    <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
                        <div
                            className="col-span-4 sm:col-span-4 md:col-span-6"
                            data-aos="zoom-in"
                            data-aos-easing="ease-in-out"
                            data-aos-duration="500"
                        >
                            <img
                                src={WhoWeAre}
                                alt="Community"
                                className="w-full h-full"
                            />
                        </div>
                        <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                            <h2
                                className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="500"
                            >
                                What is Ogao?
                            </h2>
                            <div>
                                <p
                                    className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    Barangay Ogao has been a sitio of Barangay Tiris. During the Second World War, in October, 1943, the residents led by some respected leaders decided to secede from their mother barangay and thus established their own. This decision to secede was put in effect in October  1943 when the inhabitants grew in number. They have chosen one of its residents by the name of Dionisio Fajardo as first Teniente del Barrio.
                                    Not long after the establishment of their barangay, the inhabitants started their next activity which was the establishment of the Ermita. To them, the Ermita was equally important with their barangay government not only because they have to house their patron saint Dolorosa which came from Danao, A sitio of Barangay Bagacay, but also because th Ermita was the center of their religious activities. So, by November of the same year, through bayanihan, the people were able to establish their Ermita and construct their barrio chapel. After the completion of their barrio chapel, the first mass held in the barangay.
                                    Immediately after the end of the war, the residents constructed their first school house and Grade I class was organized.
                                    Barangay Ogao was named after a locally known as "Ogao" which thrived abundantly in this place during the earlier times. This barangay was located at the eastern part of the municipality and it is bounded on the North by Barangay Paco, on the South by Barangay Tiris, on the East by Barangay Tiris and on the West by Barangay Tagaytay.
                                    Although this barangay established in 1943, it become an official barangay only after the liberation in 1945.

                                </p>
                                {/* <p
                                    className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    Bicol One Community is dedicated to eliminating poverty in the
                                    Bicol region by empowering communities through educational
                                    opportunities, increased internet accessibility, sustainable
                                    projects, and advocating for marginalized groups. Our ultimate
                                    goal is to instigate lasting transformation, nurturing hope
                                    and fostering a more equitable future for the entire region
                                    and hopefully across the country.
                                </p> */}
                            </div>
                            <div
                                className="text-right py-5"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="500"
                            >
                                <Link to="/barangays" onClick={transition}>
                                    <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                                        See other barangays
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Ogao;
