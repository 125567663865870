import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../../layouts/Footer';


const Barangays = () => {
    const [barangays, setBarangays] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch barangays from API
    useEffect(() => {
        const fetchBarangays = async () => {
            try {
                const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/barangays');
                const data = await response.json();
                setBarangays(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching barangays:', error);
                setLoading(false);
            }
        };

        fetchBarangays();
    }, []); // Empty dependency array ensures this runs once when the component mounts

    // Filter barangays based on the search term
    const filteredBarangays = barangays.filter((barangay) =>
        barangay.name.toLowerCase().includes(searchTerm.toLowerCase()) // Updated field to 'name'
    );

    return (
        <div className='overflow-y-auto'>
            <div className="min-h-screen bg-gray-100 flex flex-col ">
                {/* Header Section */}
                <header className="bg-blue-700 p-6 text-white shadow-lg">
                    <h1 className="text-3xl font-semibold text-center">Gubat Barangays</h1>

                    {/* Search Bar */}
                    <div className="mt-4 max-w-2xl mx-auto flex items-center justify-between bg-white p-3 rounded-lg shadow-md">
                        <input
                            type="text"
                            placeholder="Search Barangay..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="text-gray-500 w-full rounded-md border p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                        {/* Clear Search Button */}
                        {searchTerm && (
                            <button
                                onClick={() => setSearchTerm('')}
                                className="text-indigo-500 ml-2"
                            >
                                Clear
                            </button>
                        )}
                    </div>
                </header>



                <main className="flex-grow p-6 mb-20">
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                        {loading ? (
                            <div className="col-span-full flex justify-center items-center hover:bg-indigo-600 hover:text-white">
                                <div className="animate-spin border-4 border-indigo-600 border-t-transparent w-12 h-12 rounded-full"></div>
                            </div>
                        ) : filteredBarangays.length > 0 ? (
                            filteredBarangays.map((barangay) => (
                                <Link to={`/${barangay.name.toLowerCase().replace(/\s+/g, '-')}`} key={barangay.id}>
                                    <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow text-indigo-600 p-6 text-center hover:bg-indigo-600 hover:text-white">
                                        <h3 className="text-xl font-medium">{barangay.name}</h3>
                                    </div>
                                </Link>

                            ))
                        ) : (
                            <div className="col-span-full text-center text-gray-600">
                                No Barangays found
                            </div>
                        )}
                    </div>
                </main>

            </div>
            <Footer />
        </div>
    );
};

export default Barangays;
