import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/noimage.png'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Tagaytay() {
  // Optional: Define or import the `transition` function if necessary
  const transition = () => {
    console.log('Navigating...');
  };

  return (

    <>
      {/* WHO ARE WE SECTION */}
      <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
        <AnimatedPage>
          <div className="container mx-auto">
            <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
              <div
                className="col-span-4 sm:col-span-4 md:col-span-6"
                data-aos="zoom-in"
                data-aos-easing="ease-in-out"
                data-aos-duration="500"
              >
                <img
                  src={WhoWeAre}
                  alt="Community"
                  className="w-full h-full"
                />
              </div>
              <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                <h2
                  className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  What is Tagaytay?
                </h2>
                <div>
                  <p
                    className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="500"
                  >"Tagaytay" is another local word meaning plateau. The inhabitants called their barangay as such because the barrio site in which they choster their houses is situated on a plateau stretching from Barangay Tiris to the boundary of its adjoining barangays.

                    It was told that during those years when this place was still a thick forest, settlers from another town came to stay. a man from Labo, Camarines Sur, came to settle. He was Mamerto Panuga. He cleared enough area to plant various crops. As the years passed the settlement on the top of the plateau grew bigger. It became a sitio of Barangay Tiris.

                    Although it is nearer for the inhabitants to go to Barangay Nato than to go to Barangay Tiris, Tagaytay remain a sitio of Barangay Tiris until March 27, 1941 when the place was officially recognized as a barangay. After a year, on April 5, 1942, an election was held and Porferio Panuga, a son of Mamerto, was elected as the first Teniente del Barrio.

                  </p>
                  {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                </div>
                <div
                  className="text-right py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  <Link to="/barangays" onClick={transition}>
                    <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                      See other barangays
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </AnimatedPage>
      </section>
    </>


  );
}

export default Tagaytay;
