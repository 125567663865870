import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/noimage.png'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Buenavista() {
    // Optional: Define or import the `transition` function if necessary
    const transition = () => {
        console.log('Navigating...');
    };

    return (

        <>
            {/* WHO ARE WE SECTION */}
            <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
                <AnimatedPage>
                    <div className="container mx-auto">
                        <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
                            <div
                                className="col-span-4 sm:col-span-4 md:col-span-6"
                                data-aos="zoom-in"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="500"
                            >
                                <img
                                    src={WhoWeAre}
                                    alt="Community"
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                                <h2
                                    className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    What is Buenavista?
                                </h2>
                                <div>
                                    <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Buenavista, a barangay in Gubat, Sorsogon, is renowned for its pristine beaches, particularly Buenavista Beach, which fronts the Pacific Ocean. This beach has gained popularity, especially among surfers, thanks to its long stretch of fine sand and beautiful swells. It remains relatively undeveloped, with just a few small huts and surf camps, making it an appealing destination for those seeking a more natural, untouched beach experience
                                        

                                       
                                        .

                                        The Buenavista Surf Camp, one of the main surf camps in the area, was started by a local woman, "Lola Sayong," who saw the potential of the beach for surfing. This initiative has since become central to the area, drawing visitors for its relaxed atmosphere and affordable surfing lessons
                                       
                                        .

                                        Historically, Buenavista has become a notable site not just for its natural beauty but also as a key part of Gubat's tourism, especially as the area grows in recognition as a surfing hotspot. Visitors can enjoy a variety of activities from surfing to beachcombing and even explore nearby natural reserves
                                        
                                        .</p>
                                    {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                                </div>
                                <div
                                    className="text-right py-5"
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="500"
                                >
                                    <Link to="/barangays" onClick={transition}>
                                        <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                                            See other barangays
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedPage>
            </section>
        </>


    );
}

export default Buenavista;
