// src/axiosInstance.js
import axios from 'axios';


const token = localStorage.getItem('authToken');

const axiosInstance = axios.create({
  baseURL: 'https://peachpuff-squid-437405.hostingersite.com/api', 
  timeout: 10000 , 
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  },
});

export default axiosInstance;
