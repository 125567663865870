import React from 'react';
import { Link } from 'react-router-dom'; // Ensure this is imported if using React Router
import WhoWeAre from '../assets/barangay-about/noimage.png'; // Update the path to your image file
import { AnimatedPage } from '../FramerMotion/AnimatedPage';

function Tigkiw() {
  // Optional: Define or import the `transition` function if necessary
  const transition = () => {
    console.log('Navigating...');
  };

  return (

    <>
      {/* WHO ARE WE SECTION */}
      <section className="font-poppins pt-20 px-5 md:px-5 lg:px-10 overflow-y-auto">
        <AnimatedPage>
          <div className="container mx-auto">
            <div className="grid grid-cols-4 lg:grid-cols-12 gap-5">
              <div
                className="col-span-4 sm:col-span-4 md:col-span-6"
                data-aos="zoom-in"
                data-aos-easing="ease-in-out"
                data-aos-duration="500"
              >
                <img
                  src={WhoWeAre}
                  alt="Community"
                  className="w-full h-full"
                />
              </div>
              <div className="flex flex-col justify-between col-span-4 lg:col-span-6 lg:px-10">
                <h2
                  className="text-4xl pb-5 font-semibold 2xl:text-4xl"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  What is Tigkiw?
                </h2>
                <div>
                  <p
                    className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem] pb-10 text-justify"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="500"
                  >
                    The name "Tigkiw" was given to this barangay by an American who happened to come to this place sometime in the year 1890. According to the stories of elders, sometime in that year, The American reached the place and chanced upon a man watching a bird perched on top of a big tree. When the American asked the old man the name of the place, the old man, thinking that he was being asked about the name of the bird, replied "tique na tama" which means a bird species belonging to the family. The American understood the answer as "tigkiw". The word spread and later on, the place was known as Tigkiw.

                    Tigkiw is comprised of the Tigkiw-na-Daco (Tigkiw major) and Tigkiw-na-Saday (Tigkiw minor). The first settlement, however, was in Tigkiw-na-Saday where burial site was discovered. It was estimated that Tigkiw-na-Saday was inhabited since 200 B.C.. The first official administrator of Tigkiw was Hilario Hermogino.

                  </p>
                  {/* <p
                                        className="text-[.8rem] md:text-[1rem] 2xl:text-[1.2rem]"
                                        data-aos="fade-up"
                                        data-aos-easing="ease-in-out"
                                        data-aos-duration="500"
                                    >
                                        Bicol One Community is dedicated to eliminating poverty in the
                                        Bicol region by empowering communities through educational
                                        opportunities, increased internet accessibility, sustainable
                                        projects, and advocating for marginalized groups. Our ultimate
                                        goal is to instigate lasting transformation, nurturing hope
                                        and fostering a more equitable future for the entire region
                                        and hopefully across the country.
                                    </p> */}
                </div>
                <div
                  className="text-right py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease-in-out"
                  data-aos-duration="500"
                >
                  <Link to="/barangays" onClick={transition}>
                    <button className="btn bg-blue-500 py-4 px-8 text-white rounded-lg">
                      See other barangays
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </AnimatedPage>
      </section>
    </>


  );
}

export default Tigkiw;
