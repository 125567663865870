import React, { useState } from 'react';
import { AllPayments } from './AllPayments';
import VerifiedPayments from './VerifiedPayments';
import UnverifiedPayments from './UnverifiedPayments';
import NotValidPayments from './NotValidPayments';
import ExpiredPayments from './ExpiredPayments';

export const Payments = () => {
    // State to keep track of the active tab
    const [openTab, setOpenTab] = useState(1);

    return (
        <main className="w-full flex-grow p-6  overflow-x-hidden">
            <h1 className="text-3xl text-black">Payments</h1>

            <div className="w-full my-6">
                <ul className="flex border-b">
                    <li className="mr-1">
                    <button
                            onClick={() => setOpenTab(1)}
                            className={`bg-white inline-block py-2 px-4 font-semibold ${openTab === 2 ? 'border-l border-t border-r rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-900'}`}
                        >
                            All
                        </button>
                    </li>
                    <li className="mr-1">
                        <button
                            onClick={() => setOpenTab(2)}
                            className={`bg-white inline-block py-2 px-4 font-semibold ${openTab === 2 ? 'border-l border-t border-r rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-900'}`}
                        >
                            Verified
                        </button>
                    </li>
                    <li className="mr-1">
                        <button
                            onClick={() => setOpenTab(3)}
                            className={`bg-white inline-block py-2 px-4 font-semibold ${openTab === 4 ? 'border-l border-t border-r rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-900'}`}
                        >
                            Unverified
                        </button>
                    </li>
                    <li className="mr-1">
                        <button
                            onClick={() => setOpenTab(4)}
                            className={`bg-white inline-block py-2 px-4 font-semibold ${openTab === 4 ? 'border-l border-t border-r rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-900'}`}
                        >
                            Invalid
                        </button>
                    </li>
                    {/* <li className="mr-1">
                        <button
                            onClick={() => setOpenTab(5)}
                            className={`bg-white inline-block py-2 px-4 font-semibold ${openTab === 4 ? 'border-l border-t border-r rounded-t text-blue-700 font-semibold' : 'text-blue-500 hover:text-blue-900'}`}
                        >
                            Expired
                        </button>
                    </li> */}
                </ul>

                <div className="bg-white p-6">
                    {openTab === 1 && (
                        <AllPayments />
                    )}
                    {openTab === 2 && (
                        <VerifiedPayments />
                    )}
                    {openTab === 3 && (
                       <UnverifiedPayments />
                    )}
                    {openTab === 4 && (
                       <NotValidPayments />
                    )}
                    {/* {openTab === 5 && (
                       <ExpiredPayments />
                    )} */}
                </div>
            </div>
        </main>
    );
};

export default Payments;
