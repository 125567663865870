// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import RoleBasedRoute from './components/RoleBasedRoute';

// Import components
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import Register from './components/Register';


import Payments from './components/Payments/Payments';

import Unauthorized from './components/Unauthorized';



import Header from './layouts/Header';
import Barangay from './components/Barangays/Barangay';
import CommunityNews from './components/News/CommunityNews';
import BarangayMarketplace from './components/Marketplace/BarangayMarketplace';
import CreateUser from './components/UserManagement/CreateUser';
import PendingProducts from './components/PendingProducts/PendingProducts';
import PendingNews from './components/PendingNews/PendingNews';
import { About } from './components/About/About';
import { Home } from './components/Home/Home';
import Bulacao from './components/Barangays/Bulacao';
import Ogao from './components/Barangays/Ogao';
import Ariman from './components/Barangays/Ariman';
import Bagacay from './components/Barangays/Bagacay';
import BaludDelNorte from './components/Barangays/BaludDelNorte';
import BaludDelSur from './components/Barangays/BaludDelSur';
import Benguet from './components/Barangays/Benguet';
import Bentuco from './components/Barangays/Bentuco';
import Beriran from './components/Barangays/Beriran';
import Buenavista from './components/Barangays/Buenavista';
import Cabigaan from './components/Barangays/Cabigaan';
import Cabiguhan from './components/Barangays/Cabiguhan';
import Cariedo from './components/Barangays/Cariedo';
import Casili from './components/Barangays/Casili';
import Cogon from './components/Barangays/Cogon';
import CotaNaDaco from './components/Barangays/CotaNaDaco';
import Dita from './components/Barangays/Dita';
import Jupi from './components/Barangays/Jupi';
import Lapinig from './components/Barangays/Lapinig';
import LunaCandol from './components/Barangays/LunaCandol';
import Manapao from './components/Barangays/Manapao';
import Manook from './components/Barangays/Manook';
import Naagtan from './components/Barangays/Naagtan';
import Nato from './components/Barangays/Nato';
import Nazareno from './components/Barangays/Nazareno';
import Paco from './components/Barangays/Paco';
import Panganiban from './components/Barangays/Panganiban';
import Paradijon from './components/Barangays/Paradijon';
import Patag from './components/Barangays/Patag';
import Payawin from './components/Barangays/Payawin';
import Pinontingan from './components/Barangays/Pinontingan';
import Rizal from './components/Barangays/Rizal';
import Sangat from './components/Barangays/Sangat';
import SanIgnacio from './components/Barangays/SanIgnacio';
import SantaAna from './components/Barangays/SantaAna';
import Tabi from './components/Barangays/Tabi';
import Tagaytay from './components/Barangays/Tagaytay';
import Tigkiw from './components/Barangays/Tigkiw';
import Tiris from './components/Barangays/Tiris';
import Togawe from './components/Barangays/Togawe';
import Union from './components/Barangays/Union';
import Villareal from './components/Barangays/Villareal';
import { Donation } from './components/Donation/Donation';
import Magsasaka from './components/Magsasaka/Magsasaka';
import Games from './components/Games/Games';

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Routes */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<Register />} />
        <Route path='/unauthorized' element={<Unauthorized />} /> {/* Add Unauthorized route */}

        <Route element={<Header />}>
          <Route path='/donation' element={
            <Donation />
          } />
          <Route path='/barangays' element={

            // <RoleBasedRoute allowedRoles={[1, 2, 5]}>{
            <Barangay />
            // }</RoleBasedRoute>
          } />
          <Route path='/ariman' element={
            <Ariman />
          } />
          <Route path='/bagacay' element={
            <Bagacay />
          } />
          <Route path='/balud-del-norte-(pob.)' element={
            <BaludDelNorte />
          } />
          <Route path='/balud-del-sur-(pob.)' element={
            <BaludDelSur />
          } />
          <Route path='/benguet' element={
            <Benguet />
          } />
          <Route path='/bentuco' element={
            <Bentuco />
          } />
          <Route path='/beriran' element={
            <Beriran />
          } />
          <Route path='/buenavista' element={
            <Buenavista />
          } />
          <Route path='/cabigaan' element={
            <Cabigaan />
          } />
          <Route path='/cabiguhan' element={
            <Cabiguhan />
          } />
          <Route path='/carriedo' element={
            <Cariedo />
          } />
          <Route path='/casili' element={
            <Casili />
          } />
          <Route path='/cogon' element={
            <Cogon />
          } />
          <Route path='/cota-na-daco-(pob.)' element={
            <CotaNaDaco />
          } />
          <Route path='/dita' element={
            <Dita />
          } />
          <Route path='/jupi' element={
            <Jupi />
          } />
          <Route path='/lapinig' element={
            <Lapinig />
          } />
          <Route path='/luna-candol-(pob.)' element={
            <LunaCandol />
          } />
          <Route path='/manapao' element={
            <Manapao />
          } />
          <Route path='/manook-(pob.)' element={
            <Manook />
          } />
          <Route path='/naagtan' element={
            <Naagtan />
          } />
          <Route path='/nato' element={
            <Nato />
          } />
          <Route path='/nazareno' element={
            <Nazareno />
          } />
          <Route path='/paco' element={
            <Paco />
          } />
          <Route path='/panganiban-(pob.)' element={
            <Panganiban />
          } />
          <Route path='/paradijon-(pob.)' element={
            <Paradijon />
          } />
          <Route path='/patag' element={
            <Patag />
          } />
          <Route path='/payawin' element={
            <Payawin />
          } />
          <Route path='/pinontingan-(pob.)' element={
            <Pinontingan />
          } />
          <Route path='/rizal' element={
            <Rizal />
          } />
          <Route path='/sangat' element={
            <Sangat />
          } />
          <Route path='/san-ignacio' element={
            <SanIgnacio />
          } />
          <Route path='/santa-ana' element={
            <SantaAna />
          } />
          <Route path='/tabi' element={
            <Tabi />
          } />
          <Route path='/tagaytay' element={
            <Tagaytay />
          } />
          <Route path='/tigkiw' element={
            <Tigkiw />
          } />
          <Route path='/tiris' element={
            <Tiris />
          } />
          <Route path='/togawe' element={
            <Togawe />
          } />
          <Route path='/union' element={
            <Union />
          } />
          <Route path='/villareal' element={
            <Villareal />
          } />

          <Route path='/bulacao' element={
            <Bulacao />
          } />

          <Route path='/ogao' element={

            // <RoleBasedRoute allowedRoles={[1, 2, 5]}>{
            <Ogao />
            // }</RoleBasedRoute>
          } />
          <Route path='/marketplace' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <BarangayMarketplace />
            // }</RoleBasedRoute>
          } />

          <Route path='/news' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <CommunityNews />
            // }</RoleBasedRoute>
          } />

          <Route path='/about' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <About />
            // }</RoleBasedRoute>
          } />

          <Route path='/magsasaka' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <Magsasaka />
            // }</RoleBasedRoute>
          } />

          <Route path='/b-games' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <Games />
            // }</RoleBasedRoute>
          } />


          <Route path='/' element={
            // <RoleBasedRoute allowedRoles={[1, 2]}>{
            <Home />
            // }</RoleBasedRoute>
          } />
        </Route>



        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<Header />}>
            {/* <Route index element={<Dashboard />} /> */}

            <Route path='/pending-products' element={
              <RoleBasedRoute allowedRoles={[1]}>{<PendingProducts />}</RoleBasedRoute>
            } />

            <Route path='/pending-news' element={
              <RoleBasedRoute allowedRoles={[1]}>{<PendingNews />}</RoleBasedRoute>
            } />




            <Route path='/payments' element={
              <RoleBasedRoute allowedRoles={[1, 2]}>{<Payments />}</RoleBasedRoute>
            } />


            <Route path='/create-user' element={
              <RoleBasedRoute allowedRoles={[1]}>{<CreateUser />}</RoleBasedRoute>
            } />


          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
