import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Chart, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import axiosInstance from '../AxiosInstance';
import { AuthContext } from '../auth/AuthContext';

// Register chart.js components
Chart.register(...registerables);

const Dashboard = () => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [signupData, setSignupData] = useState({ labels: [], datasets: [] });
    const [totalMonthlyCollection, setTotalMonthlyCollection] = useState('0.00');
    const [totalYearlyCollection, setTotalYearlyCollection] = useState('0.00');
    const [totalDailyCollection, setTotalDailyCollection] = useState('0.00'); // New state for daily data
    const [totalCurrentUsers, setTotalCurrentUsers] = useState(0);
    const [monthlyNewUsers, setMonthlyNewUsers] = useState(0);
    const [yearlyNewUsers, setYearlyNewUsers] = useState(0);
    const [dailyNewUsers, setDailyNewUsers] = useState(0); // New state for daily data
    const [monthlyUnverifiedCollection, setMonthlyUnverifiedCollection] = useState('0.00');
    const [monthlyUnverified, setMonthlyUnverified] = useState(0);
    const [yearlyUnverifiedCollection, setYearlyUnverifiedCollection] = useState('0.00');
    const [yearlyUnverified, setYearlyUnverified] = useState(0);
    const [dailyUnverifiedCollection, setDailyUnverifiedCollection] = useState('0.00'); // New state for daily data
    const [dailyUnverified, setDailyUnverified] = useState(0); // New state for daily data
    const [monthlyVerified, setMonthlyVerified] = useState(0);
    const [yearlyVerified, setYearlyVerified] = useState(0);
    const [dailyVerified, setDailyVerified] = useState(0); // New state for daily data
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [timeRange, setTimeRange] = useState('daily');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // New state for daily data

    const { userRole } = useContext(AuthContext); // Access userRole from context

    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);

            const endpoint = timeRange === 'daily' ? '/daily-data' : timeRange === 'monthly' ? '/monthly-data' : '/yearly-data';
            const params = timeRange === 'daily' ? { date: selectedDate } : { month: selectedMonth, year: selectedYear };

            const response = await axiosInstance.get(endpoint, { params });
            const data = response?.data || {};



            if (timeRange === 'daily') {
                setTotalDailyCollection(data.daily_verified_collection || '0.00');
                setDailyNewUsers(data.daily_registered_clients || 0);
                setDailyUnverifiedCollection(data.daily_unverified_collection || '0.00');
                setDailyUnverified(data.daily_unverified || 0);
                setDailyVerified(data.daily_verified || 0);
                setTotalCurrentUsers(data.total_current_register || 0);
                setChartData({
                    labels: ['Daily Collection'],
                    datasets: [{
                        label: 'Daily Payment Collection',
                        data: [data.daily_verified_collection] || [],
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    }]
                });
                setSignupData({
                    labels: ['Daily Sign-ups'],
                    datasets: [{
                        label: 'Daily Sign-ups',
                        data: [data.daily_registered_clients] || [],
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    }]
                });
            } else {
                setTotalMonthlyCollection(data.monthly_verified_collection || '0.00');
                setTotalYearlyCollection(data.yearly_verified_collection || '0.00');
                setTotalCurrentUsers(data.total_current_register || 0);
                setMonthlyNewUsers(data.monthly_registered_clients || 0);
                setYearlyNewUsers(data.yearly_registered_clients || 0);
                setMonthlyUnverifiedCollection(data.monthly_unverified_collection || '0.00');
                setMonthlyUnverified(data.monthly_unverified || 0);
                setYearlyUnverifiedCollection(data.yearly_unverified_collection || '0.00');
                setYearlyUnverified(data.yearly_unverified || 0);
                setMonthlyVerified(data.monthly_verified || 0);
                setYearlyVerified(data.yearly_verified || 0);
                setChartData({
                    labels: [timeRange === 'monthly' ? 'Monthly Collection' : 'Yearly Collection'],
                    datasets: [{
                        label: timeRange === 'monthly' ? 'Monthly Payment Collection' : 'Yearly Payment Collection',
                        data: [timeRange === 'monthly' ? data.monthly_verified_collection : data.yearly_verified_collection] || [],

                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    }]
                });
                setSignupData({
                    labels: [timeRange === 'monthly' ? 'Monthly Sign-ups' : 'Yearly Sign-ups'],
                    datasets: [{
                        label: timeRange === 'monthly' ? 'Monthly Sign-ups' : 'Yearly Sign-ups',
                        data: [timeRange === 'monthly' ? data.monthly_registered_clients : data.yearly_registered_clients] || [],
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    }]
                });
            }

        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 20000); // Refresh every 20 seconds

        return () => clearInterval(interval);
    }, [timeRange, selectedMonth, selectedYear, selectedDate]);

    if (loading) return <div className="text-center text-gray-500 py-10">Loading...</div>;
    if (error) return <div className="text-center text-red-500 py-10">{error}</div>;

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: { beginAtZero: true },
            y: { beginAtZero: true }
        },
        responsive: true,
    };

    const handleTimeRangeChange = (e) => {
        const value = e.target.value;
        setTimeRange(value);
        if (value === 'daily') {
            setSelectedDate(new Date().toISOString().split('T')[0]);
        }
        else if (value === 'monthly') {
            setSelectedMonth(new Date().getMonth() + 1);
            setSelectedDate(new Date().toISOString().split('T')[0]);
        } else if (value === 'yearly') {
            setSelectedYear(new Date().getFullYear());
            setSelectedDate(new Date().toISOString().split('T')[0]);
        }
    };

    const currentYear = new Date().getFullYear();
    const startYear = 2010;

    const years = Array.from({ length: currentYear - startYear + 1 }, (v, i) => startYear + i);

    const yearOptions = years.map(year => (
        <option key={year} value={year}>
            {year}
        </option>
    ));

    const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value, 10));
    const handleYearChange = (e) => setSelectedYear(parseInt(e.target.value, 10));
    const handleDateChange = (e) => setSelectedDate(e.target.value);





    return (
        <div className="w-full p-6 bg-gray-100 min-h-screen overflow-x-hidden">

            {(userRole === 1 || userRole === 2) && (
                <>
                    <div className='flex justify-between p-4'>
                        <h1 className="text-3xl text-black">Dashboard</h1>
                        <div className='flex items-center'>
                            {timeRange === 'monthly' && (
                                <>
                                    <select
                                        name="month"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        className="border p-2 rounded-lg"
                                    >
                                        {[...Array(12).keys()].map(i => (
                                            <option key={i + 1} value={i + 1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
                                        ))}
                                    </select>
                                    <select name="year" value={selectedYear} onChange={handleYearChange} className="border p-2 rounded-lg">
                                        {yearOptions}
                                    </select>
                                </>
                            )}
                            {timeRange === 'yearly' && (
                                <select name="year" value={selectedYear} onChange={handleYearChange} className="border p-2 rounded-lg">
                                    {yearOptions}
                                </select>
                            )}
                            {/* {timeRange === 'daily' && (
                        <input
                            type="date"
                            name="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            className="border p-2 rounded-lg"
                        />
                    )} */}

                            <select
                                name="timeRange"
                                value={timeRange}
                                onChange={handleTimeRangeChange}
                                className="border p-2 rounded-lg bg-blue-900 text-white"
                            >
                                <option value="daily">Daily</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </select>
                        </div>

                    </div>
                </>
            )}

            <div className="flex flex-wrap">
                {(userRole === 1 || userRole === 2) && (
                    <>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-blue-300 to-blue-100 border-b-4 border-blue-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-blue-600">
                                                <i className="fa fa-wallet fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Verified Collection</h2>
                                            <p className="font-bold text-2xl"><span className='px-1'>PHP</span>{timeRange === 'daily' ? totalDailyCollection : timeRange === 'yearly' ? totalYearlyCollection : totalMonthlyCollection}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-red-300 to-red-100 border-b-4 border-red-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-red-600">
                                                <i className="fa fa-times fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Unverified Amount</h2>
                                            <p className="font-bold text-2xl"><span className='px-1'>PHP</span>{timeRange === 'daily' ? dailyUnverifiedCollection : timeRange === 'yearly' ? yearlyUnverifiedCollection : monthlyUnverifiedCollection}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/clients'>
                                <div className="bg-gradient-to-b from-violet-300 to-violet-100 border-b-4 border-violet-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-violet-600">
                                                <i className="fa fa-users fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Total Registered</h2>
                                            <p className="font-bold text-2xl">{timeRange === 'daily' ? totalCurrentUsers : totalCurrentUsers}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-green-300 to-green-100 border-b-4 border-green-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-green-600">
                                                <i className="fa fa-check fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Verified Payments</h2>
                                            <p className="font-bold text-2xl">{timeRange === 'daily' ? dailyVerified : timeRange === 'yearly' ? yearlyVerified : monthlyVerified}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-orange-300 to-orange-100 border-b-4 border-orange-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-orange-600">
                                                <i className="fa fa-hourglass-half fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Unverified Payments</h2>
                                            <p className="font-bold text-2xl">{timeRange === 'daily' ? dailyUnverified : timeRange === 'yearly' ? yearlyUnverified : monthlyUnverified}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/clients'>
                                <div className="bg-gradient-to-b from-yellow-300 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-yellow-600">
                                                <i className="fa fa-calendar-plus fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">New Clients</h2>
                                            <p className="font-bold text-2xl">{timeRange === 'daily' ? dailyNewUsers : timeRange === 'yearly' ? yearlyNewUsers : monthlyNewUsers}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </>
                )}
                {(userRole === 5) && (
                    <>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-blue-300 to-blue-100 border-b-4 border-blue-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-blue-600">
                                                <i className="fa fa-wallet fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Total Balance</h2>
                                            <p className="font-bold text-2xl"><span className='px-1'>PHP</span>{timeRange === 'daily' ? totalDailyCollection : timeRange === 'yearly' ? totalYearlyCollection : totalMonthlyCollection}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-yellow-300 to-blue-100 border-b-4 border-yellow-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-yellow-600">
                                                <i className="fa fa-wallet fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Online Users</h2>
                                            <p className="font-bold text-2xl"><span className='px-1'>PHP</span>{timeRange === 'daily' ? totalDailyCollection : timeRange === 'yearly' ? totalYearlyCollection : totalMonthlyCollection}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <Link to='/payments'>
                                <div className="bg-gradient-to-b from-green-300 to-blue-100 border-b-4 border-green-600 rounded-lg shadow-lg p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pr-4">
                                            <div className="rounded-full p-3 bg-green-600">
                                                <i className="fa fa-wallet fa-2x text-white" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="flex-1 text-right md:text-center">
                                            <h2 className="font-bold uppercase text-gray-700">Registered Clients</h2>
                                            <p className="font-bold text-2xl"><span className='px-1'>PHP</span>{timeRange === 'daily' ? totalDailyCollection : timeRange === 'yearly' ? totalYearlyCollection : totalMonthlyCollection}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </>
                )}

            </div>

            <div className="flex flex-wrap mt-6">
                {/* <div className="w-full md:w-1/2 xl:w-1/2 p-4">
                    <div className="bg-white border rounded-lg shadow-xl p-5 h-96">
                        <h2 className="font-bold text-xl mb-4">{timeRange === 'daily' ? 'Daily Payment Collection' : timeRange === 'monthly' ? 'Monthly Payment Collection' : 'Yearly Payment Collection'}</h2>
                        <Bar data={chartData} options={chartOptions} />
                    </div>
                </div> */}
                {/* <div className="w-full md:w-1/2 xl:w-1/2 p-4">
                    <div className="bg-white border rounded-lg shadow-xl p-5 h-96">
                        <h2 className="font-bold text-xl mb-4">{timeRange === 'daily' ? 'Daily Sign-ups' : timeRange === 'monthly' ? 'Monthly Sign-ups' : 'Yearly Sign-ups'}</h2>
                        <Line data={signupData} options={chartOptions} />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Dashboard;
