import React, { Component } from 'react';
import axiosInstance from '../../AxiosInstance';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { Link } from 'react-router-dom';

export class VerifiedPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payments: [],
            filteredPayments: [],
            selectedBillingId: null,
            referenceNumber: '',
            isVerifying: false,
            proofOfPaymentUrl: '',
            showModal: false,
        };
    }

    componentDidMount() {
        this.fetchPayments();
    }

    fetchPayments = async () => {
        try {
            const response = await axiosInstance.get('/billings');
            const verifiedPayments = response.data.filter(payment => payment.status === "Verified");
            this.setState({ payments: verifiedPayments, filteredPayments: verifiedPayments });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    handleNotValidPayment = async () => {
        const { selectedBillingId } = this.state;
        const authId = localStorage.getItem('authId');

        if (!authId) {
            console.error('User ID not found in localStorage');
            return;
        }

        try {
            await axiosInstance.post(`/not-valid/${selectedBillingId}`, { user_id: authId });
            this.fetchPayments();
            this.toggleModal();
        } catch (error) {
            console.error('Error marking payment as not valid:', error.response ? error.response.data : error.message);
        }
    };

    handleVerifiedPayment = async () => {
        const { selectedBillingId, referenceNumber } = this.state;
        const userId = localStorage.getItem('authId');

        if (!userId) {
            console.error('Auth ID is not set. Please log in again.');
            return;
        }

        try {
            await axiosInstance.post(`/verified/${selectedBillingId}`, { reference_number: referenceNumber, user_id: userId });
            this.fetchPayments();
            this.toggleModal();
        } catch (error) {
            console.error('Error verifying payment:', error.response ? error.response.data : error.message || error);
        }
    };

    handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        const filteredPayments = this.state.payments.filter(payment =>
            payment.hotspots[0]?.voucher.toLowerCase().includes(query)
        );
        this.setState({ filteredPayments });
    };

    toggleModal = (billingId = null, isVerifying = false, proofUrl = '') => {
        this.setState({
            selectedBillingId: billingId,
            isVerifying,
            proofOfPaymentUrl: proofUrl,
            showModal: !this.state.showModal,
        });
    };

    handleReferenceNumberChange = (event) => {
        this.setState({ referenceNumber: event.target.value });
    };

    formatDateTime = (dateTime) => {
        return new Date(dateTime).toLocaleDateString();
    };

    render() {
        const { filteredPayments, isVerifying, referenceNumber, proofOfPaymentUrl, showModal } = this.state;
        const url = 'http://157.20.8.25:8000';

        return (
            <div>
                <div className="w-full mt-6 px-6">
                    <p className="text-xl pb-3 flex items-center justify-between">
                        <span><i className="fas fa-list mr-3"></i>Verified Payments</span>
                        <input
                            type="text"
                            className='w-1/3 py-3 px-4 rounded-full border border-green-900'
                            placeholder='Search Voucher Code'
                            onChange={this.handleSearch}
                        />
                    </p>
                    <div className="bg-white overflow-auto">
                        <table className="min-w-full bg-white">
                            <thead className="bg-gray-800 text-white">
                                <tr>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Account Name</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Account Number</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Voucher Code</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Amount</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Date Paid</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Proof of Payment</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Reference Number</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Status</th>
                                    <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700">
                                {filteredPayments.map(payment => (
                                    <tr key={payment.id} className='hover:bg-blue-200'>
                                        <td className="text-left py-1 px-4">
                                            <Link to={`/transactions/${payment.client.id}`} className="hover:text-blue-500">
                                                {payment.client.account_name}
                                            </Link>
                                        </td>
                                        <td className="text-left py-1 px-4">
                                            <Link to={`/transactions/${payment.client.id}`} className="hover:text-blue-500">
                                                {payment.client.account_number}
                                            </Link>
                                        </td>
                                        <td className="text-left py-1 px-4">
                                            {payment.hotspots.length > 0 ? payment.hotspots[0].voucher : 'No voucher'}
                                        </td>
                                        <td className="text-left py-1 px-4">{payment.plan.amount}</td>
                                        <td className="text-left py-1 px-4">{this.formatDateTime(payment.date_paid)}</td>
                                        
                                        <td className="text-left py-1 px-4">
                                            <a href={`${url}/storage/${payment.proof_of_payment}`} className="hover:text-blue-500" target="_blank" rel="noopener noreferrer">View Receipt</a>
                                        </td>

                                        <td className="text-left py-1 px-4">{payment.reference_number || 'N/A'}</td>
                                        <td className="text-left py-1 px-4">
                                            <span className={`py-1 px-4 inline-block text-white rounded-full ${payment.status === 'Payment Not Valid'
                                                ? 'bg-red-600'
                                                : payment.status === 'Unverified'
                                                    ? 'bg-orange-400'
                                                    : 'bg-green-600'
                                                }`}
                                            >
                                                {payment.status}
                                            </span>
                                        </td>
                                        <td className="text-left py-1 px-4">
                                            <div className='flex gap-2'>
                                                <button
                                                    onClick={() => this.toggleModal(payment.hotspots[0].id, true, `${url}/storage/${payment.proof_of_payment}`)}
                                                    className="hover:text-blue-500 text-lg text-green-600"
                                                >
                                                    <FaCheck />
                                                </button>
                                                <button
                                                    onClick={() => this.toggleModal(payment.hotspots[0].id, false)}
                                                    className="hover:text-blue-500 text-red-600"
                                                >
                                                    <ImCross />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {showModal && (
                    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-md w-full lg:w-1/3">
                            <h2 className="text-lg mb-4">Confirm Action</h2>
                            {isVerifying && proofOfPaymentUrl && (
                                <div className="flex items-center justify-center mb-4 h-96">
                                    <img src={`${proofOfPaymentUrl}`} className='h-96' alt="Proof of Payment" />
                                </div>
                            )}
                            <p>
                                {isVerifying ? 'Are you sure you want to verify this payment?' : 'Are you sure you want to mark this payment as "Not Valid"?'}
                            </p>
                            {isVerifying && (
                                <input
                                    type="text"
                                    className="w-full mt-4 py-2 px-3 border border-gray-300 rounded-md"
                                    placeholder="Enter Reference Number"
                                    value={referenceNumber}
                                    onChange={this.handleReferenceNumberChange}
                                />
                            )}
                            <div className="flex justify-end mt-6 gap-2">
                                <button
                                    onClick={this.toggleModal}
                                    className="bg-gray-500 text-white py-2 px-4"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={isVerifying ? this.handleVerifiedPayment : this.handleNotValidPayment}
                                    className={`py-2 px-4 text-white ${isVerifying ? 'bg-blue-800' : 'bg-red-600'}`}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default VerifiedPayments;
