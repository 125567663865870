import React, { Component } from 'react';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import axiosInstance from '../../AxiosInstance';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext'; // Adjust path as necessary

export class AllPayments extends Component {
    static contextType = AuthContext; // Access context in the class component

    state = {
        billings: [],
        searchQuery: '',
        showModal: false,
        selectedBillingId: null,
        isVerifying: false,
        referenceNumber: '',
        proofOfPaymentUrl: '', // Track proof of payment URL
        error: '', // Track error message
    };

    componentDidMount() {
        this.fetchBillings();
    }

    fetchBillings = async () => {
        try {
            const response = await axiosInstance.get('/billings');
            this.setState({ billings: response.data });
        } catch (error) {
            console.error('Error fetching billings:', error);
        }
    };

    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value });
    };

    toggleModal = (billingId = null, isVerifying = false, proofOfPaymentUrl = '') => {
        this.setState((prevState) => ({
            showModal: !prevState.showModal,
            selectedBillingId: billingId,
            isVerifying: isVerifying,
            referenceNumber: '', // Reset reference number
            proofOfPaymentUrl: proofOfPaymentUrl, // Set proof of payment URL
            error: '', // Reset error message
        }));
    };

    handleNotValidPayment = async () => {
        const { selectedBillingId } = this.state;
        const authId = localStorage.getItem('authId'); // Retrieve authId from localStorage
    
        // Log the retrieved authId to debug
        console.log('Retrieved authId from localStorage:', authId);
    
        if (!authId) {
            console.error('User ID not found in localStorage');
            return;
        }
    
        try {
            const userId = authId;
    
            // Log the integer userId to debug
            console.log('Sending userId to backend:', userId);
    
            // Make API request to mark payment as not valid
            const response = await axiosInstance.post(`/not-valid/${selectedBillingId}`, { user_id: userId });
    
            if (response.status === 200) {
                console.log('Marked payment as not valid:', selectedBillingId);
                // Refresh the billings list
                this.fetchBillings();
                // Close the modal
                this.toggleModal();
            } else {
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Error marking payment as not valid:', error.response ? error.response.data : error.message);
            this.setState({ error: 'Error marking payment as not valid. Please try again.' });
        }
    };

    handleVerifiedPayment = async () => {
        const { selectedBillingId, referenceNumber } = this.state;
        const userId = localStorage.getItem('authId'); // Retrieve authId from localStorage
    
        try {
            console.log('Verifying payment with ID:', selectedBillingId);
            console.log('Reference number:', referenceNumber);
            console.log('User ID:', userId);
    
            // Check if userId is defined
            if (!userId) {
                throw new Error('Auth ID is not set. Please log in again.');
            }
    
            // Prepare the payload
            const payload = { reference_number: referenceNumber, user_id: userId };
            console.log('Payload being sent:', payload);
    
            // Send the request
            const response = await axiosInstance.post(`/verified/${selectedBillingId}`, payload);
            
            // Log the response from the server
            console.log('Payment verification response:', response.data);
    
            // Refresh the billings list and close the modal
            this.fetchBillings();
            this.toggleModal(); 
        } catch (error) {
            // Log detailed error information
            console.error('Error verifying payment:', error.response ? error.response.data : error.message || error);
            this.setState({ error: 'Error verifying payment. Please check the reference number and try again.' });
        }
    };

    handleReferenceNumberChange = (e) => {
        this.setState({ referenceNumber: e.target.value });
    };

    formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        // Adjust for Philippine Time Zone (UTC+8)
        date.setHours(date.getHours() + 8);

        // Define options for formatting
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        // Replace the "at" and join the date and time parts
        return formattedDate.replace(', ', ' ').replace(' at ', ' ');
    };

    render() {
        const { billings, searchQuery, showModal, isVerifying, referenceNumber, proofOfPaymentUrl, error } = this.state;

        const url = 'http://157.20.8.25:8000';

        const filteredBillings = billings.filter(billing =>
            billing.hotspots.length > 0 && billing.hotspots[0].voucher.toLowerCase().includes(searchQuery.toLowerCase())
        );

        return (
            <div className="w-full mt-6 px-6 overflow-x-hidden">
                <p className="text-xl pb-3 flex items-center justify-between">
                    <span><i className="fas fa-list mr-3" aria-hidden="true"></i> All Payments</span>
                    <input
                        type="text"
                        className='w-1/3 py-3 px-4 rounded-full border border-green-900'
                        placeholder='Search Voucher Code'
                        value={searchQuery}
                        onChange={this.handleSearchChange}
                    />
                </p>
                <div className="bg-white overflow-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-800 text-white">
                            <tr>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Account Name</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Account Number</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Voucher Code</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Amount</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Date Paid</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Start Date</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">End Date</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Proof of Payment</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Reference Number</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Status</th>
                                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {filteredBillings.map(billing => (
                                <tr key={billing.id} className='hover:bg-blue-200'>
                                    <td className="text-left py-1 px-4">
                                        <Link to={`/transactions/${billing.client.id}`} className="hover:text-blue-500">
                                            {billing.client.account_name}
                                        </Link>
                                    </td>
                                    <td className="text-left py-1 px-4">
                                        <Link to={`/transactions/${billing.client.id}`} className="hover:text-blue-500">
                                            {billing.client.account_number}
                                        </Link></td>
                                    <td className="text-left py-1 px-4">
                                        {billing.hotspots.length > 0 ? billing.hotspots[0].voucher : 'No voucher'}
                                    </td>
                                    <td className="text-left py-1 px-4">{billing.plan.amount}</td>
                                    <td className="text-left py-1 px-4">{this.formatDateTime(billing.date_paid)}</td>
                                    
                                    <td className="text-left py-1 px-4">{this.formatDateTime(billing.hotspots[0].start_date) || 'N/A'}</td>
                                    <td className="text-left py-1 px-4">{this.formatDateTime(billing.hotspots[0].end_date)  || 'N/A'}</td>
                                    <td className="text-left py-1 px-4">
                                        <a href={`${url}/storage/${billing.proof_of_payment}`} className="hover:text-blue-500" target="_blank" rel="noopener noreferrer">View Receipt</a>
                                    </td>
                                    <td className="text-left py-1 px-4">{billing.reference_number || 'N/A'}</td>
                                    <td className="text-left py-1 px-4"><span
                                        className={`py-1 px-4 inline-block text-white rounded-full ${billing.status === 'Payment Not Valid'
                                            ? 'bg-red-600'
                                            : billing.status === 'Unverified'
                                                ? 'bg-orange-400'
                                                : 'bg-green-600'
                                            }`}
                                    >
                                        {billing.status}
                                    </span>
                                    </td>
                                    <td className="text-left py-1 px-4">
                                        <div className='flex gap-2'>
                                            <button
                                                onClick={() => this.toggleModal(billing.hotspots[0].id, true, `${url}/storage/${billing.proof_of_payment}`)}
                                                className="hover:text-blue-500 text-lg text-green-600"
                                            >
                                                <FaCheck />
                                            </button>
                                            <button
                                                onClick={() => this.toggleModal(billing.hotspots[0].id, false)}
                                                className="hover:text-blue-500 text-red-600"
                                            >
                                                <ImCross />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {showModal && (
                    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-md w-full lg:w-1/3">
                            <h2 className="text-lg mb-4">Confirm Action</h2>
                            {isVerifying && proofOfPaymentUrl && (
                                <div className="flex items-center justify-center mb-4 h-96">
                                    <img src={proofOfPaymentUrl} className='h-96' alt="Proof of Payment" />
                                </div>
                            )}
                            <p>
                                {isVerifying ? 'Are you sure you want to verify this payment?' : 'Are you sure you want to mark this payment as "Not Valid"?'}
                            </p>
                            {isVerifying && (
                                <>
                                    <input
                                        type="text"
                                        className="w-full mt-4 py-2 px-3 border border-gray-300 rounded-md"
                                        placeholder="Enter Reference Number"
                                        value={referenceNumber}
                                        onChange={this.handleReferenceNumberChange}
                                    />
                                    {error && <p className="text-red-600 mt-2">{error}</p>}
                                </>
                            )}
                            <div className="flex justify-end mt-6 gap-2">
                                <button
                                    onClick={this.toggleModal}
                                    className="bg-gray-500 text-white py-2 px-4 "
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={isVerifying ? this.handleVerifiedPayment : this.handleNotValidPayment}
                                    className={`py-2 px-4 text-white ${isVerifying ? 'bg-blue-800' : 'bg-red-600'}`}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default AllPayments;
